import { favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { GetItemMetadataCompactResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const getItemMetadataCompact = async (itemNos: string) => {
  const path = endpointReplace(
    { ':siteType': SiteTypes.FAVOURITES, ':itemNos': itemNos },
    Endpoints.GetItemMetadataCompact,
  );

  const authentication = await window.ikea.mvAgent.authentication?.isLoggedIn();

  const response = await network.get<GetItemMetadataCompactResponse>(path, {
    ...(authentication?.authToken && {
      headers: {
        'x-auth-token': authentication.authToken,
      },
    }),
  });

  return response.data;
};
