import './CreateListAndAddToFavouritesModal.scss';

import warningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';
import { useRef, useState } from 'react';

import { useError } from '../../../hooks/useError';
import { useLoading } from '../../../hooks/useLoading';
import { EventName, Form } from '../../Form/Form';
import { FormField, RuleType } from '../../FormField/FormField';
import Button, { ButtonType } from '../../Skapa/Button/Button';
import InlineMessage, { InlineMessageVariant } from '../../Skapa/InlineMessage/InlineMessage';
import InputField, { InputFieldType } from '../../Skapa/InputField/InputField';
import Modal, { ModalBody, ModalFooter, ModalHeader, SheetSize, Sheets } from '../../Skapa/Modal/Modal';
import Text, { TextTagName } from '../../Skapa/Text/Text';

type Labels = {
  modal: {
    title: string;
    body: string;
  };
  footer: {
    save: {
      label: string;
    };
  };
  fields: {
    newListName: {
      label: string;
      error: {
        required: string;
        maxLength: string;
      };
    };
  };
  error: {
    tooManyFavouritesLists: {
      title: string;
      body: string;
    };
    default: {
      title: string;
      body: string;
    };
  };
};
enum LocalLoading {
  SAVE = 'save',
  ADD = 'add',
}
enum LocalError {
  TOO_MANY_FAVOURITE_LISTS = 'too_many_favourite_lists',
  NO_ACCESS = 'no_access',
  LIST_NOT_FOUND = 'list_not_found',
  UNKNOWN = 'unknown',
}

type SaveResponse = {
  id: string;
  name: string;
};
export interface CreateListAndAddToFavouritesModalProps {
  isOpen: boolean;
  labels: Labels;
  onClose: () => void;
  onBack?: () => void;
  onSave: (newListName: string) => Promise<SaveResponse>;
  onSaveFail: (error: any) => void;
  onAddItemToFavourites: (listId: string) => Promise<string>;
  onAddItemToFavouritesCallback: (listId: string, listName: string, itemId: string) => void;
  onAddItemToFavouritesFallback: (listId: string, listName: string, error: any) => void;
}
export const CreateListAndAddToFavouritesModal = (props: CreateListAndAddToFavouritesModalProps) => {
  const {
    isOpen,
    labels,
    onClose,
    onBack,
    onSave,
    onSaveFail,
    onAddItemToFavourites,
    onAddItemToFavouritesCallback,
    onAddItemToFavouritesFallback,
  } = props;
  const localLoading = useLoading<LocalLoading>([]);
  const localErrorOptions = Object.values(LocalError);
  const localError = useError([], localErrorOptions);
  const formReference = useRef<HTMLFormElement>(null);
  const [newListName, setNewListName] = useState('');

  if (localError.unallowed().length > 0) throw Error(localError.unallowed()[0]);

  const getErrorLabels = (type: string) =>
    type === LocalError.TOO_MANY_FAVOURITE_LISTS
      ? { title: labels.error.tooManyFavouritesLists.title, body: labels.error.tooManyFavouritesLists.body }
      : { title: labels.error.default.title, body: labels.error.default.body };

  const submitForm = () => {
    formReference.current?.dispatchEvent(new Event(EventName.SUBMIT, { bubbles: true, cancelable: true }));
  };

  const resetForm = () => {
    setNewListName('');
    localError.clear();
  };

  return (
    <Modal
      id={'create-list-modal'}
      visible={isOpen}
      handleCloseBtn={() => {
        onClose();
        resetForm();
      }}
      scrollLock={false}
    >
      <Sheets
        id={'create-list-sheets-modal'}
        header={
          <ModalHeader
            title={labels.modal.title}
            backBtnClick={
              onBack &&
              (() => {
                onBack();
                resetForm();
              })
            }
          />
        }
        size={SheetSize.SMALL}
        footer={
          <ModalFooter>
            <Button
              type={ButtonType.PRIMARY}
              fluid={true}
              onClick={submitForm}
              text={labels.footer.save.label}
              loading={localLoading.check(LocalLoading.SAVE)}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <Text tagName={TextTagName.P} className={'create-list-body'}>
            {labels.modal.body}
          </Text>
          <Form
            onSubmit={async () => {
              let newListId: string = '';
              try {
                localLoading.set(LocalLoading.SAVE);
                const saveResponse = await onSave(newListName);
                newListId = saveResponse.id;
              } catch (error: any) {
                localError.set(error.type as string);
                onSaveFail(error);
              } finally {
                localLoading.unset(LocalLoading.SAVE);
              }
              if (newListId) {
                try {
                  localLoading.set(LocalLoading.ADD);
                  const itemId = await onAddItemToFavourites(newListId);
                  onAddItemToFavouritesCallback(newListId, newListName, itemId);
                } catch (error: any) {
                  onAddItemToFavouritesFallback(newListId, newListName, error);
                } finally {
                  localLoading.unset(LocalLoading.ADD);
                  resetForm();
                }
              }
            }}
            reference={formReference}
          >
            <FormField
              validationRules={[
                { type: RuleType.MAX_LENGTH, value: 50, message: labels.fields.newListName.error.maxLength },
                { type: RuleType.REQUIRED, value: true, message: labels.fields.newListName.error.required },
              ]}
            >
              <InputField
                id={'new-list-field'}
                label={labels.fields.newListName.label}
                value={newListName}
                type={InputFieldType.TEXT}
                onChange={(e) => {
                  setNewListName(e.target.value);
                }}
              />
            </FormField>
          </Form>
          {localError.value.map((v) => (
            <InlineMessage
              key={v}
              ssrIcon={warningTriangleIcon}
              variant={InlineMessageVariant.NEGATIVE}
              title={getErrorLabels(v).title}
              body={getErrorLabels(v).body}
              className={'inlineErrorMessage'}
            />
          ))}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
