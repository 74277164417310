import { favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { CreateListResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const createList = async (name?: string) => {
  const path = endpointReplace({ ':siteType': SiteTypes.FAVOURITES }, Endpoints.Lists);
  const authentication = await window.ikea.mvAgent.authentication?.isLoggedIn();

  const response = await network.post<CreateListResponse>(
    path,
    {
      name: name,
    },
    {
      ...(authentication?.authToken && {
        headers: {
          'x-auth-token': authentication.authToken,
        },
      }),
    },
  );

  return response.data;
};
