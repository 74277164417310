import Cookies from 'js-cookie';
import { JSX, createContext, useCallback, useMemo, useState } from 'react';

import { setupAuth0Session, setupSession } from '../network/axios';
import { Cookie } from '../network/enums';
import { getAuthProvider } from '../network/identityProvider/getAuthProvider';
import { Provider } from '../network/identityProvider/types';
import { stringToBoolean } from '@mvecom/common/utils/stringToBoolean';
import { isCiamEnabled } from '../utils/retailUnitsWithCiam';

export const IdentityContext = createContext(
  {} as {
    sessionState: SessionState;
    getSession: (forceUpdate?: boolean) => Promise<void>;
    getAuthProvider: () => Provider;
  },
);

export interface IdentityProviderProps {
  children: JSX.Element;
}

export enum SessionState {
  PENDING = 'pending',
  FAILED = 'failed',
  DONE = 'done',
  IDLE = 'idle',
}

export const IdentityProvider = (props: IdentityProviderProps) => {
  const { children } = props;
  const [sessionState, setSessionState] = useState(SessionState.IDLE);

  const get = useCallback(
    async (forceUpdate: boolean = false) => {
      if (sessionState !== SessionState.PENDING) {
        if (
          !forceUpdate &&
          (stringToBoolean(Cookies.get(Cookie.USER_LOGGEDIN)) ||
            (Cookies.get(Cookie.FAVOURITES_GUEST_ID) &&
              Cookies.get(Cookie.FAVOURITES_GUEST_PROVIDER) &&
              Cookies.get(Cookie.CART_GUEST_ID) &&
              Cookies.get(Cookie.CART_GUEST_PROVIDER)))
        ) {
          if (sessionState !== SessionState.DONE) {
            setSessionState(SessionState.DONE);
          }
        } else {
          setSessionState(SessionState.PENDING);
          if (isCiamEnabled) {
            await setupAuth0Session();
          } else {
            await setupSession();
          }
          setSessionState(SessionState.DONE);
        }
      }
    },
    [sessionState],
  );

  return (
    <IdentityContext.Provider
      value={useMemo(() => ({ sessionState, getSession: get, getAuthProvider }), [get, sessionState])}
    >
      {children}
    </IdentityContext.Provider>
  );
};
