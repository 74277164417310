import Cookies from 'js-cookie';
import { getAuthServiceUrl } from './url';
import { isCiamEnabled } from './retailUnitsWithCiam';
import { splitPath } from '@mvecom/common/utils/splitPath';
import { Cookie } from '../network/enums';
import { setupSession } from '../network/axios';
import { stringToBoolean } from '@mvecom/common/utils/stringToBoolean';
import { Authentication } from '../network/types';

const [retailUnit] = splitPath(window.location.pathname);
const AUTH0_COOKIE = `auth0-session-${retailUnit}`;

type TokenPayload = { exp: number };

function getAuthTokenPayloadFromCookie(): TokenPayload | undefined {
  const cookieValue = Cookies.get(AUTH0_COOKIE);
  if (cookieValue) {
    const [, payload] = cookieValue.split('.');
    if (payload) {
      const jsonToken = JSON.parse(atob(payload));
      return jsonToken;
    }
  }
}

function isTokenExpired(payload: TokenPayload) {
  const exp = payload.exp * 1000;
  return new Date(exp) < new Date();
}

function isTokenAboutToExpire(payload: TokenPayload) {
  const fifteenMinutes = 15 * 60 * 1000;
  return payload.exp * 1000 - new Date().getTime() < fifteenMinutes;
}

export function authHelper() {
  // Only one simultaneous isLoggedIn call is permitted
  let isLoggedInPromise: Promise<Authentication> | undefined;
  let pending = false;
  const isLoggedIn = async () => {
    if (!isCiamEnabled) {
      if (!Cookies.get(Cookie.USER_LOGGEDIN)) {
        await setupSession();
      }
      const isLoggedIn = stringToBoolean(Cookies.get(Cookie.USER_LOGGEDIN));
      return { isLoggedIn, authToken: undefined, provider: 'icaps' };
    }

    const tokenPayload = getAuthTokenPayloadFromCookie();
    if (!tokenPayload) {
      return { isLoggedIn: false, authToken: undefined, provider: 'icaps' };
    }

    if (isTokenExpired(tokenPayload)) {
      const response = await fetch(`${getAuthServiceUrl()}/status/`);
      const { isAuthenticated } = await response.json();
      if (!isAuthenticated) {
        return { isLoggedIn: false, authToken: undefined, provider: 'icaps' };
      }
    } else if (isTokenAboutToExpire(tokenPayload)) {
      await fetch(`${getAuthServiceUrl()}/refresh/`);
    }

    return { isLoggedIn: true, authToken: Cookies.get(AUTH0_COOKIE), provider: 'ciam' };
  };

  return {
    getTokens: async () => {
      const tokenPayload = getAuthTokenPayloadFromCookie();
      if (!tokenPayload) return;

      if (isTokenExpired(tokenPayload)) {
        await fetch(`${getAuthServiceUrl()}/status/`);
      } else if (isTokenAboutToExpire(tokenPayload)) {
        await fetch(`${getAuthServiceUrl()}/refresh/`);
      }

      // If/when we get guest tokens, add them in the same way but under guest.
      // ex. {guest:{value, expirationTime}, auth:{value, expirationTime}}
      return { auth: { value: Cookies.get(AUTH0_COOKIE), expirationTime: getAuthTokenPayloadFromCookie()?.exp } };
    },
    isLoggedIn: async (force = false) => {
      if (!pending || force) {
        isLoggedInPromise = isLoggedIn();
        pending = true;
      }
      const out = await isLoggedInPromise;
      pending = false;
      return out;
    },
  };
}
