import { getRetailUnitAndLanguage } from '../network/utils/getRetailUnitAndLanguage';
import { getEnv } from './env';
const { retailUnit } = getRetailUnitAndLanguage();

interface RetailUnitsCiam {
  [key: string]: (typeof env)[];
}

const RETAIL_UNITS_WITH_CIAM: RetailUnitsCiam = { kw: ['development', 'qa', 'production'] };

const env = getEnv();

export const isCiamEnabled = RETAIL_UNITS_WITH_CIAM?.[retailUnit]?.includes(env);
