import { useCallback, useEffect, useState } from 'react';
import { PREFIX } from '../../Skapa/constants/constants';
import { Wrapper } from '../standardModalWrapper';
import { Actions } from '../../../actions/enums';
import { isCiamEnabled } from '../../../utils/retailUnitsWithCiam';
import Text, { TextTagName } from '../../Skapa/Text/Text';
import Button, { ButtonType } from '../../Skapa/Button/Button';
import Loading, { LoadingBall } from '@ingka/loading';

import styles from './CiamLoginModal.module.scss';
import { useTranslation } from 'react-i18next';
import { getAuthServiceUrl } from '../../../utils/url';
import { getRetailUnitAndLanguage } from '../../../network/utils/getRetailUnitAndLanguage';
import useAuth0 from '../../../hooks/useAuth0';
import appMetadata from '@mvecom/common/utils/appMetadata';
import { useFetchMenu } from '../../../hooks/useMenu';
import { MenuLoggedIn } from '../LoginModal/Menu';

export const CiamLoginModal = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('profile');
  const { retailUnit, language } = getRetailUnitAndLanguage();
  const authServiceBaseUrl = getAuthServiceUrl();
  const { status: authStatus, setLoggedOut } = useAuth0(visible);
  const menu = useFetchMenu(authStatus === 'Authenticated');

  useEffect(() => {
    if (menu.status === 'error' && menu.axiosStatusCode === 401) {
      setLoggedOut();
    }
  }, [menu, setLoggedOut]);

  const open = useCallback(() => {
    if (!isCiamEnabled || appMetadata.appName === 'profile') return;
    setVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    window?.ikea?.pubsub?.subscribe(Actions.PROFILE_OPEN_MENU, open);

    return () => {
      window?.ikea?.pubsub?.unsubscribe(Actions.PROFILE_OPEN_MENU, open);
    };
  }, [open]);

  const login = useCallback(() => {
    window.location.href = toUrl(`${authServiceBaseUrl}/login`, {
      lang: language,
      retailUnit,
      referrer: window.location.href,
    });
  }, [authServiceBaseUrl, language, retailUnit]);

  const signup = useCallback(() => {
    window.location.href = toUrl(`${authServiceBaseUrl}/signup`, {
      lang: language,
      retailUnit,
      referrer: window.location.href,
    });
  }, [authServiceBaseUrl, language, retailUnit]);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Wrapper
      noPadding={authStatus === 'Authenticated' && !!menu.data}
      onClosed={menu.clear}
      visible={visible}
      close={close}
    >
      {authStatus === 'Loading' && (
        <Loading prefix={PREFIX} text="">
          <LoadingBall prefix={PREFIX} />
        </Loading>
      )}
      {authStatus === 'Authenticated' &&
        (menu.data ? (
          <MenuLoggedIn menu={menu.data} />
        ) : (
          <Loading prefix={PREFIX} text="">
            <LoadingBall prefix={PREFIX} />
          </Loading>
        ))}

      {authStatus === 'Unauthenticated' && (
        <div>
          <Text tagName={TextTagName.H2} className={styles.header}>
            {t('loginPrompt.alternativeOption.header')}
          </Text>
          <Text tagName={TextTagName.P}>{t('loginPage.loginPageHeaderDescription')}</Text>
          <Button
            onClick={login}
            className={styles.button}
            prefix={PREFIX}
            fluid
            data-testid="login"
            text={t('loginPage.loginButton')}
            type={ButtonType.PRIMARY}
          />
          <div className={styles.divider}>
            <Text tagName={TextTagName.SPAN}>{t('loginPrompt.newIkea.text')}</Text>
          </div>
          <Button
            onClick={signup}
            className={styles.button}
            prefix={PREFIX}
            fluid
            data-testid="login"
            text={t('loginPage.createAccountButton')}
            type={ButtonType.SECONDARY}
          />
        </div>
      )}
    </Wrapper>
  );
};

function toUrl(path: string, searchParams: Record<string, string>) {
  const url = new URL(path);

  for (const [key, value] of Object.entries(searchParams)) {
    url.searchParams.set(key, value);
  }

  return url.toString();
}
