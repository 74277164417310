import { splitPath } from '@mvecom/common/utils/splitPath';
import { use as i18use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import backendModule from './backendModule';

export const initLanguage = async () => {
  const [retailUnit, language] = splitPath(window.location.pathname);
  const cultureCode = `${language.toLowerCase()}-${retailUnit.toUpperCase()}`;
  const queries = new URLSearchParams(window.location.search);
  return i18use(initReactI18next)
    .use(backendModule)
    .init({
      debug: process.env.NODE_ENV === 'development',
      saveMissing: true,
      interpolation: {
        escapeValue: false,
        format: undefined,
      },
      fallbackLng: false,
      ns: ['translations', 'profile'],
      load: 'currentOnly',
      defaultNS: 'translations',
      lng: queries.has('showKeys') ? 'cimode' : cultureCode,
    });
};
