import { favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { FetchListsResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const getLists = async () => {
  const path = endpointReplace({ ':siteType': SiteTypes.FAVOURITES }, Endpoints.Lists);
  const authentication = await window.ikea.mvAgent.authentication?.isLoggedIn();

  const response = await network.get<FetchListsResponse>(path, {
    ...(authentication?.authToken && {
      headers: {
        'x-auth-token': authentication.authToken,
      },
    }),
  });

  return response.data.results;
};
