import './AddOrRemoveItemModal.scss';

import heartIcon from '@ingka/ssr-icon/paths/heart';
import trashCanIcon from '@ingka/ssr-icon/paths/trash-can';

import Button, { ButtonType } from '../../Skapa/Button/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader, SheetSize, Sheets } from '../../Skapa/Modal/Modal';
import Text, { TextBodySize } from '../../Skapa/Text/Text';

type Labels = {
  modal: {
    title: string;
    body: string;
  };
  footer: {
    goToAddItem: {
      label: string;
    };
    goToRemoveItem: {
      label: string;
    };
  };
};
export enum Error {
  UNKNOWN = 'unknown',
}
export interface AddOrRemoveItemProps {
  isOpen: boolean;
  labels: Labels;
  onClose: () => void;
  onAdd: () => void;
  onRemove: () => void;
}
export const AddOrRemoveItem = (props: AddOrRemoveItemProps) => {
  const { isOpen, labels, onClose, onAdd, onRemove } = props;

  return (
    <Modal id={'add-or-remove-modal'} visible={isOpen} handleCloseBtn={() => onClose()} scrollLock={false}>
      <Sheets
        id={'add-or-remove-sheets-modal'}
        header={<ModalHeader title={labels.modal.title} />}
        size={SheetSize.SMALL}
        footer={
          <ModalFooter className="modalFooter">
            <Button
              ssrIcon={trashCanIcon}
              fluid={true}
              onClick={() => {
                onRemove();
              }}
              text={labels.footer.goToRemoveItem.label}
            />
            <Button
              ssrIcon={heartIcon}
              fluid={true}
              onClick={() => {
                onAdd();
              }}
              text={labels.footer.goToAddItem.label}
              type={ButtonType.PRIMARY}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <Text bodySize={TextBodySize.M}>{labels.modal.body}</Text>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
