import './App.scss';

import { useContext, useEffect, useState } from 'react';
import { cookie } from '@mvecom/common/utils/clientCookieManager';
import { stringToBoolean } from '@mvecom/common/utils/stringToBoolean';
import { LoginModal } from './components/Modals/LoginModal/LoginModal';
import { MvAgentCart } from './components/MvAgentCart/MvAgentCart';
import { MvAgentFavourites } from './components/MvAgentFavourites/MvAgentFavourites';
import { setupAuth0Session, setupSession } from './network/axios';
import { TranslationsContext, TranslationsState } from './providers/TranslationsProvider';
import { isCiamEnabled } from './utils/retailUnitsWithCiam';
import { CiamLoginModal } from './components/Modals/CiamLoginModal/CiamLoginModal';
import { authHelper } from './utils/auth';
import { Actions } from './actions/enums';
import { getCookieDomain } from './network/utils/getCookieDomain';
import { getRetailUnitAndLanguage } from './network/utils/getRetailUnitAndLanguage';

declare global {
  interface Window {
    ikea: Record<Exclude<string, 'mvAgent'>, any> & {
      mvAgent: Record<Exclude<string, 'isUserLoggedIn'>, any> & {
        isUserLoggedIn: () => Promise<boolean>;
      };
    };
    sendEvent?: any;
  }
}

const App = () => {
  const { translationsState, getTranslations } = useContext(TranslationsContext);
  const [loaded, setLoaded] = useState<Array<'cart' | 'favourites'>>([]);

  useEffect(() => {
    getTranslations();
  }, [getTranslations]);

  useEffect(() => {
    // Only used for CIAM
    if (loaded.includes('cart') && loaded.includes('favourites')) {
      if (stringToBoolean(cookie.get('auth0-logged-in'))) {
        cookie.del('auth0-logged-in', `/${getRetailUnitAndLanguage().retailUnit}`, getCookieDomain);
        window.ikea.pubsub.publish(Actions.USER_LOGGED_IN);
      }
      if (stringToBoolean(cookie.get('auth0-logged-out'))) {
        cookie.del('auth0-logged-out', `/${getRetailUnitAndLanguage().retailUnit}`, getCookieDomain);
        window.ikea.pubsub.publish(Actions.USER_LOGGED_OUT);
      }
    }
  }, [loaded]);

  useEffect(() => {
    window.ikea.mvAgent = window.ikea.mvAgent || {};
    window.ikea.mvAgent.isUserLoggedIn = async () =>
      isCiamEnabled ? (await setupAuth0Session()).isAuthenticated : (await setupSession()).isLoggedIn;
    window.ikea.mvAgent.authentication = authHelper();
  }, []);

  useEffect(() => {
    const agentLoaded = (agent: 'cart' | 'favourites') => () => {
      setLoaded((v) => [...v, agent]);
    };

    window.ikea.pubsub.subscribe(Actions.CART_AGENT_LOADED, agentLoaded('cart'));
    window.ikea.pubsub.subscribe(Actions.FAVOURITES_AGENT_LOADED, agentLoaded('favourites'));
  }, []);

  return (
    <>
      {translationsState === TranslationsState.DONE && (
        <>
          <MvAgentCart />
          <MvAgentFavourites />
          {isCiamEnabled ? <CiamLoginModal /> : <LoginModal />}
        </>
      )}
    </>
  );
};

export default App;
