import trashCan from '@ingka/ssr-icon/paths/trash-can';
import pencil from '@ingka/ssr-icon/paths/pencil';
import lock from '@ingka/ssr-icon/paths/lock';
import creditCard from '@ingka/ssr-icon/paths/credit-card';
import person from '@ingka/ssr-icon/paths/person';
import deliveryTruck from '@ingka/ssr-icon/paths/delivery-truck';
import home from '@ingka/ssr-icon/paths/home';
import box from '@ingka/ssr-icon/paths/box';
import board from '@ingka/ssr-icon/paths/board';

import arrowRightFromBase from '@ingka/ssr-icon/paths/arrow-right-from-base';

import ListView, { ListViewItem } from '@ingka/list-view';
import Text from '@ingka/text';
import { useMemo } from 'react';
import { PREFIX } from '../../Skapa/constants/constants';
import { MenuData, MenuLinkInfo } from './menu-types';
import { getAuthServiceUrl, toUrl } from '../../../utils/url';
import appMetaData from '@mvecom/common/utils/appMetadata';
import { useTranslation } from 'react-i18next';
import { isCiamEnabled } from '../../../utils/retailUnitsWithCiam';
import { useIcapsLogout } from '../../../hooks/useIcapsLogout';

import styles from './Menu.module.scss';

function iconTypeToIcon(icon: MenuLinkInfo['icon']) {
  switch (icon) {
    case 'board':
      return board;
    case 'box':
      return box;
    case 'delivery-truck':
      return deliveryTruck;
    case 'home':
      return home;
    case 'credit-card':
      return creditCard;
    case 'lock':
      return lock;
    case 'pencil':
      return pencil;
    case 'person':
      return person;
    case 'trash-can':
      return trashCan;
    default:
      console.warn('missing icon for icon name: ', icon);
      return undefined;
  }
}

const Child = ({ model }: { model: MenuLinkInfo }) => (
  <ListViewItem
    emphasised
    inset
    size="large"
    key={model.title}
    prefix={PREFIX}
    title={model.title}
    description={model.subtitle}
    control="link"
    leadingIcon={iconTypeToIcon(model.icon)}
    href={model.href}
    controlIcon="chevronRightSmall"
    className={styles.listViewItem}
  />
);

export function MenuLoggedIn({ menu, onLoggedOut }: Readonly<{ menu: MenuData; onLoggedOut?: () => void }>) {
  const authServiceBaseUrl = getAuthServiceUrl();
  const { t } = useTranslation('profile');

  const icapsLogout = useIcapsLogout(onLoggedOut);

  const logoutHref = useMemo(() => {
    if (!appMetaData.language) {
      throw Error('No language found when logging out in mvagent');
    }

    if (!appMetaData.retailUnit) {
      throw Error('No retailUnit found when logging out in mvagent');
    }

    return toUrl(`${authServiceBaseUrl}/logout`, {
      lang: appMetaData.language,
      retailUnit: appMetaData.retailUnit,
      referrer: window.location.href,
    });
  }, [authServiceBaseUrl]);

  return (
    <div className={styles.wrapper}>
      <Text tagName="h1" headingSize="s" className={styles.heading}>
        {menu.greeting}
      </Text>
      <nav>
        <ListView id="main-page-navigation" prefix={PREFIX} className={styles.listView} showDivider={false}>
          {menu.links.map((x) => (
            <Child key={x.title} model={x} />
          ))}

          {isCiamEnabled ? (
            <ListViewItem
              className={styles.logoutButton}
              prefix={PREFIX}
              title={t('generic.logOut.linkText')}
              href={logoutHref}
              showDivider={false}
              controlIcon={null}
              leadingIcon={arrowRightFromBase}
              control="link"
            />
          ) : (
            <ListViewItem
              className={styles.logoutButton}
              prefix={PREFIX}
              title={t('generic.logOut.linkText')}
              onClick={icapsLogout.logout}
              showDivider={false}
              controlIcon={null}
              leadingIcon={arrowRightFromBase}
              control="navigational"
            />
          )}
        </ListView>
      </nav>
    </div>
  );
}
