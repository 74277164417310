import { profileAxiosInstance as network } from '../network/axios';
import { useCallback, useMemo, useState } from 'react';
import { Endpoints, SiteTypes } from '../network/enums';
import { endpointReplace } from '../network/utils/endpointReplace';
import { useUpdateBroadcastChannel } from '@mvecom/common/hooks/useUpdateBroadcastChannel';
import { Actions } from '../actions/enums';

import appMetaData from '@mvecom/common/utils/appMetadata';

type IcapsLogoutState =
  | {
      state: 'loading' | 'initial' | 'success';
      error?: never;
    }
  | {
      state: 'error';
      error: Error;
    };

export const useIcapsLogout = (onLoggedOut?: () => void) => {
  const [logoutState, setLogoutState] = useState<IcapsLogoutState>({
    state: 'initial',
  });

  if (!appMetaData.retailUnit) {
    throw Error('No retail unit');
  }

  const broadcast = useUpdateBroadcastChannel('profile_pages_update', appMetaData.retailUnit);

  const logout = useCallback(() => {
    if (logoutState.state === 'loading') {
      return;
    }

    setLogoutState({
      state: 'loading',
    });

    network
      .delete(endpointReplace({ ':siteType': SiteTypes.PROFILE }, Endpoints.Auth))
      .then(({ status }) => {
        const loggedOut = status >= 200 && status <= 202;

        if (loggedOut) {
          setLogoutState({ state: 'success' });
          onLoggedOut?.();
          broadcast({
            type: 'profile',
            action: 'signed-out',
          });
          window?.ikea?.pubsub?.publish?.(Actions.USER_LOGGED_OUT);
        } else {
          setLogoutState({ state: 'error', error: new Error('Logout error status: ' + status) });
        }
      })
      .catch((e) => {
        console.log('error while logging out', e);
        setLogoutState({
          state: 'error',
          error: e,
        });
      });
  }, [broadcast, onLoggedOut, logoutState.state]);

  return useMemo(() => ({ logoutState, logout }), [logoutState, logout]);
};
