import { profileAxiosInstance as network } from '../network/axios';
import { useEffect, useMemo, useState } from 'react';
import { Endpoints, SiteTypes } from '../network/enums';
import { endpointReplace } from '../network/utils/endpointReplace';
import { MenuData } from '../components/Modals/LoginModal/menu-types';
import { isAxiosError } from 'axios';

type FetchResult =
  | {
      status: 'initial';
      data?: never;
    }
  | {
      status: 'success';
      data: MenuData;
    }
  | {
      status: 'loading';
      data?: MenuData;
    }
  | {
      status: 'error';
      data?: never;
      axiosStatusCode?: number;
    };

export const useFetchMenu = (enabled: boolean) => {
  const [fetchState, setFetchState] = useState<FetchResult>({ status: 'initial' });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const controller = new AbortController();

    setFetchState((oldData) => ({ status: 'loading', data: oldData.data }));

    network
      .get(endpointReplace({ ':siteType': SiteTypes.PROFILE }, Endpoints.Menu), {
        signal: controller.signal,
      })
      .then((menuData) => {
        if (!controller.signal.aborted) {
          if (menuData) {
            setFetchState({ status: 'success', data: menuData.data });
          } else {
            setFetchState({ status: 'error' });
          }
        }
      })
      .catch((e) => {
        console.error(e);
        if (isAxiosError(e)) {
          setFetchState({
            status: 'error',
            axiosStatusCode: e.status,
          });
        } else {
          setFetchState({
            status: 'error',
          });
        }
      });

    return () => {
      controller.abort();
    };
  }, [enabled]);

  return useMemo(() => ({ ...fetchState, clear: () => setFetchState({ status: 'initial' }) }), [fetchState]);
};
