import './RemovedListsModal.scss';

import { useLoading } from '../../../hooks/useLoading';
import Button, { ButtonType } from '../../Skapa/Button/Button';
import Modal, { ModalFooter, ModalHeader, Prompt } from '../../Skapa/Modal/Modal';
import Text, { TextBodySize, TextHeadingSize, TextTagName } from '../../Skapa/Text/Text';
import SkapaList, { ListVariation } from '../../Skapa/List/List';

type Labels = {
  modal: {
    title: string;
    subtitle: string;
    body: string;
  };
  footer: {
    close: {
      label: string;
    };
  };
};
type List = {
  name: string;
};
export enum Error {
  UNKNOWN = 'unknown',
}
enum LocalLoading {
  REMOVE = 'remove',
}
export interface RemovedListsModalProps {
  isOpen: boolean;
  labels: Labels;
  lists: List[];
  onClose: () => void;
}
export const RemovedListsModal = (props: RemovedListsModalProps) => {
  const { isOpen, labels, lists, onClose } = props;
  const localLoading = useLoading<LocalLoading>([]);

  return (
    <Modal id={'removed-lists-modal'} visible={isOpen} handleCloseBtn={() => onClose()} scrollLock={false}>
      <Prompt
        id={'removed-lists-prompt-modal'}
        titleId={labels.modal.title}
        title={labels.modal.title}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button
              fluid={true}
              onClick={() => onClose()}
              text={labels.footer.close.label}
              loading={localLoading.check(LocalLoading.REMOVE)}
              type={ButtonType.PRIMARY}
            />
          </ModalFooter>
        }
      >
        <Text tagName={TextTagName.P} bodySize={TextBodySize.M} className="body">
          {labels.modal.body}
        </Text>
        <Text tagName={TextTagName.H4} headingSize={TextHeadingSize.XS}>
          {labels.modal.subtitle}
        </Text>
        {lists && (
          <SkapaList id={'removed-lists-list'} variation={ListVariation.SMALL}>
            {lists.map((list) => (
              <li key={list.name}>{list.name}</li>
            ))}
          </SkapaList>
        )}
      </Prompt>
    </Modal>
  );
};
