import { JSX, createContext, useMemo } from 'react';
import { LocalStorageKeys } from '../actions/enums';

export const RedirectContext = createContext(
  {} as {
    signUpRedirect: boolean;
    loginRedirect: boolean;
  },
);

export interface RedirectProviderProps {
  children: JSX.Element;
}

export const RedirectProvider = (props: RedirectProviderProps) => {
  const { children } = props;
  const signUpRedirect = localStorage.getItem(LocalStorageKeys.SIGNED_UP_WITH_REDIRECT) === 'true';
  localStorage.removeItem(LocalStorageKeys.SIGNED_UP_WITH_REDIRECT);
  const loginRedirect = localStorage.getItem(LocalStorageKeys.LOGGED_IN_WITH_REDIRECT) === 'true';
  localStorage.removeItem(LocalStorageKeys.LOGGED_IN_WITH_REDIRECT);
  return (
    <RedirectContext.Provider
      value={useMemo(() => ({ signUpRedirect, loginRedirect }), [signUpRedirect, loginRedirect])}
    >
      {children}
    </RedirectContext.Provider>
  );
};
