import { useState, useEffect, useCallback, useMemo } from 'react';
import { getAuth0Session } from '../network/endpoints/profile-pages/getAuth0Session';
import { ProfilePagesUpdateMessage, useUpdateBroadcastChannel } from '@mvecom/common/hooks/useUpdateBroadcastChannel';
import { getRetailUnitAndLanguage } from '../network/utils/getRetailUnitAndLanguage';
import { Actions } from '../actions/enums';

type AuthStatus = 'Loading' | 'Authenticated' | 'Unauthenticated';

export default function useAuth0(run: boolean) {
  const [status, setStatus] = useState<AuthStatus>('Loading');
  const { retailUnit } = getRetailUnitAndLanguage();

  const setLoggedOut = useCallback(() => {
    setStatus('Unauthenticated');
  }, []);

  useEffect(() => {
    const ev = (event: MessageEvent) => {
      const pubsubStart = 'pubsub::';

      if (event.data === pubsubStart + Actions.USER_LOGGED_OUT) {
        setStatus('Unauthenticated');
      }

      if (event.data === pubsubStart + Actions.USER_LOGGED_IN) {
        setStatus('Authenticated');
      }
    };

    window.addEventListener('message', ev);
    return () => window.removeEventListener('message', ev);
  }, []);

  useUpdateBroadcastChannel(
    'profile_pages_update',
    retailUnit,
    useCallback((message: ProfilePagesUpdateMessage) => {
      if (message.action === 'signed-in') {
        setStatus('Authenticated');
      }

      if (message.action === 'signed-out') {
        setStatus('Unauthenticated');
      }
    }, []),
  );

  useEffect(() => {
    if (!run) {
      return;
    }

    const getSessionStatus = async (abortController: AbortController) => {
      setStatus('Loading');

      try {
        const sessionStatus = await getAuth0Session(abortController.signal);

        if (!abortController.signal.aborted) {
          setStatus(sessionStatus.isAuthenticated ? 'Authenticated' : 'Unauthenticated');
        }
      } catch (error) {
        console.error(error);
        if (!abortController.signal.aborted) {
          setStatus('Unauthenticated');
        }
      }
    };

    const abortController = new AbortController();
    getSessionStatus(abortController);

    return () => {
      abortController.abort();
    };
  }, [run]);

  return useMemo(() => ({ status, setLoggedOut }), [status, setLoggedOut]);
}
