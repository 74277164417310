import Loading, { LoadingBall } from '@ingka/loading';
import { ReactNode } from 'react';

import Modal, { ModalBody, ModalHeader, SheetSize, Sheets } from '../Skapa/Modal/Modal';
import { PREFIX } from '../Skapa/constants/constants';

export const Wrapper = ({
  children,
  visible,
  onClosed,
  loading,
  title,
  noPadding,
  close,
}: {
  children?: ReactNode;
  loading?: boolean;
  visible: boolean;
  onClosed?: () => void;
  noPadding?: boolean;
  title?: string;
  close: () => void;
}) => (
  <Modal onModalClosed={onClosed} prefix={PREFIX} id="menuModal" visible={visible} handleCloseBtn={close} scrollLock>
    <Sheets
      prefix={PREFIX}
      id="menuSheetsModal"
      header={<ModalHeader title={title} />}
      size={SheetSize.SMALL}
      footer={null}
      preserveAlignment
    >
      <ModalBody style={noPadding ? { paddingInline: '0' } : undefined} prefix={PREFIX} className={'skapasModalBody'}>
        <div className="menuModalBodyContainer">
          {!!loading && (
            <Loading prefix={PREFIX} text="">
              <LoadingBall prefix={PREFIX} />
            </Loading>
          )}
          {children}
        </div>
      </ModalBody>
    </Sheets>
  </Modal>
);
