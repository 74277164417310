import { favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { GenericResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const deleteItemFromList = async (listId: string, itemId: string) => {
  const path = endpointReplace(
    { ':siteType': SiteTypes.FAVOURITES, ':listId': listId, ':itemId': itemId },
    Endpoints.RemoveItemFromFavourites,
  );

  const authentication = await window.ikea.mvAgent.authentication?.isLoggedIn();

  const response = await network.delete<GenericResponse>(path, {
    ...(authentication?.authToken && {
      headers: {
        'x-auth-token': authentication.authToken,
      },
    }),
  });

  return response.data;
};
