import { favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { FavouritesUserReplacementResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const postUserReplacement = async (userId: string, userSource: string) => {
  const path = endpointReplace({ ':siteType': SiteTypes.FAVOURITES }, Endpoints.PostUserReplacement);
  const authentication = await window.ikea.mvAgent.authentication?.isLoggedIn();

  const response = await network.post<FavouritesUserReplacementResponse>(
    path,
    {
      userId: userId,
      userSource: userSource,
    },
    {
      ...(authentication?.authToken && {
        headers: {
          'x-auth-token': authentication.authToken,
        },
      }),
    },
  );

  return response.data;
};
