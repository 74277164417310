import { t } from 'i18next';
import Cookies from 'js-cookie';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Actions } from '../actions/enums';
import { EventName } from '../hooks/useAnalytics';
import { useCartAnalytics } from '../hooks/useCartAnalytics';
import { getCart } from '../network/endpoints/cart/getCart';
import { postAddItems } from '../network/endpoints/cart/postAddItems';
import { postUserReplacement } from '../network/endpoints/cart/postUserReplacement';
import { Cookie, Storage, SyntheticEvent } from '../network/enums';
import { ErrorType, TAddToCartPayload, TCartItem } from '../network/types';
import { getCookieDomain } from '../network/utils/getCookieDomain';
import { getRetailUnitAndLanguage } from '../network/utils/getRetailUnitAndLanguage';
import { getRetailUnitCookiePath } from '../network/utils/getRetailUnitCookiePath';
import { goToBag } from '../network/utils/goToBag';
import { IdentityContext } from '../providers/IdentityProvider';
import { useToast } from './useToast';
import { RedirectContext } from '../providers/RedirectProvider';
import appMetaData from '@mvecom/common/utils/appMetadata';
import { Application } from '@mvecom/common/enums/Application';

const recModalSources = ['pip', 'pip-sticky', 'pip-zip-in', 'pip-click-collect'];

type OnCartMergeEventListener = (status: 'failed' | 'merged' | 'did-nothing') => void;

const cartMergeListeners = new Set<OnCartMergeEventListener>();

export const useCartMergeListener = (onMerge: OnCartMergeEventListener) => {
  useEffect(() => {
    cartMergeListeners.add(onMerge);
    return () => {
      cartMergeListeners.delete(onMerge);
    };
  }, [onMerge]);
};

export const useCart = () => {
  const toast = useToast();
  const { retailUnit } = getRetailUnitAndLanguage();
  const { getSession } = useContext(IdentityContext);
  const { signUpRedirect, loginRedirect } = useContext(RedirectContext);
  const { sendErrorEvent, sendCartMergeConflictEvent, sendAddToCartEvent } = useCartAnalytics();
  const [cartAgentLoaded, setCartAgentLoaded] = useState(false);

  const [showItemDoesNotExistModal, setShowItemDoesNotExistModal] = useState(false);

  const [cartCount, setCartCount] = useState(Cookies.get(`${Cookie.CART_COUNT}${retailUnit.toUpperCase()}`));
  const cartCountAsNumber = useMemo(() => (cartCount ? parseInt(cartCount, 10) : 0), [cartCount]);
  const { appName } = appMetaData;
  const onCartPage = appName === Application.CART;
  const onFavouritesPage = appName === Application.FAVOURITES;
  const onProfilePage = appName === Application.PROFILE;

  const showMergeNotificationStorageVariable = useMemo(
    () => `${Storage.CART_SHOW_MERGE_TOAST}_${retailUnit.toUpperCase()}`,
    [retailUnit],
  );

  const baseGoToBagToastFields = useMemo(
    () => ({
      actionButtonText: t('fa.toast.button.goToBag.label'),
      ariaLabelCloseBtn: t('fa.toast.button.goToBag.label'),
      actionClick: goToBag,
    }),
    [],
  );

  const setCartCallback = useCallback(
    (count: number) => {
      const value = Math.max(count, 0).toString();
      setCartCount(value);
      Cookies.set(`${Cookie.CART_COUNT}${retailUnit.toUpperCase()}`, value, {
        path: '/',
        domain: getCookieDomain,
      });
      document.body.dispatchEvent(new Event(SyntheticEvent.ADD_TO_CART, { bubbles: true, cancelable: true }));
    },
    [retailUnit],
  );

  const increaseCartCount = useCallback(
    (count: number = 1) => {
      const setCountTo = cartCountAsNumber + count;
      setCartCallback(setCountTo);
    },
    [cartCountAsNumber, setCartCallback],
  );

  const decreaseCartCount = useCallback(
    (count: number = 1) => {
      const setCountTo = cartCountAsNumber - count;
      setCartCallback(setCountTo);
    },
    [cartCountAsNumber, setCartCallback],
  );

  const addToCartSuccess = useCallback(
    (e: TAddToCartPayload, data: TCartItem[]) => {
      window.ikea.pubsub.publish(Actions.ADD_TO_CART_SUCCESS, {
        ...e,
        items: data,
      } as TAddToCartPayload);

      sendAddToCartEvent(data, e.source);
      let quantity = 0;
      e.items.forEach((item) => (quantity += parseInt(`${item.quantity}`, 10)));
      increaseCartCount(quantity);

      if (!e.skipToast && !recModalSources.includes(e.source)) {
        if (e.label) {
          toast.set({
            ...baseGoToBagToastFields,
            text: e.label,
          });
        } else {
          e.items.forEach((item) => {
            toast.set({
              ...(!window.location.pathname.includes('/cart/') && baseGoToBagToastFields),
              text: t('fa.toast.addToBag.label', { item: item.productName }),
            });
          });
        }
      }
      if (recModalSources.includes(e.source)) {
        window.ikea.pubsub.publish(Actions.SHOW_REC_MODAL, {
          ...e,
          items: data,
        } as TAddToCartPayload);
      }
    },
    [baseGoToBagToastFields, increaseCartCount, sendAddToCartEvent, toast],
  );

  const addToCartFail = useCallback(
    (error: { type: ErrorType }, e: TAddToCartPayload) => {
      window.ikea.pubsub.publish(Actions.ADD_TO_CART_FAIL, {
        errorMessage: error,
        ...e,
      });

      if (!e.isUndoAction) {
        if (error?.type === ErrorType.INVALID_QUANTITY) {
          toast.set({
            text: t('fa.toast.maxQuantityError.text'),
          });
        } else {
          setShowItemDoesNotExistModal(true);
        }
      }

      sendErrorEvent({ eventName: EventName.ADD_TO_CART, error });
    },
    [sendErrorEvent, toast],
  );

  const setCartCookiesAndUpdateCounter = useCallback(async () => {
    if (!Cookies.get(Cookie.MV_CART_ID)) {
      const results = await getCart({ queryParams: { skipSync: true, includePrices: false } });
      const count = results.reduce(
        (current: number, previous: TCartItem) => current + parseInt(previous.quantity.toString(), 10),
        0,
      );
      setCartCallback(count);
      return count;
    }
    return undefined;
  }, [setCartCallback]);

  const setCartCookiesAndUpdateCounterDefault0 = useCallback(
    async () => (await setCartCookiesAndUpdateCounter()) ?? 0,
    [setCartCookiesAndUpdateCounter],
  );

  const doAddToCart = useCallback(
    async (e: TAddToCartPayload) => {
      window.ikea.pubsub.publish(Actions.ADD_TO_CART_INITIATE, e);
      let data: TCartItem[];
      try {
        await setCartCookiesAndUpdateCounter();
        data = await postAddItems(e.items);
      } catch (error) {
        const err = error as { type: ErrorType };
        addToCartFail(err, e);
        return;
      }
      addToCartSuccess(e, data);
    },
    [addToCartFail, addToCartSuccess, setCartCookiesAndUpdateCounter],
  );

  const addToCartListenerCallback = useCallback(
    async (e: TAddToCartPayload) => {
      await getSession();
      await doAddToCart(e);
    },
    [doAddToCart, getSession],
  );

  const listenForAddToCart = useCallback(() => {
    window.ikea.pubsub.subscribe(Actions.ADD_TO_CART_ACTION, addToCartListenerCallback);
  }, [addToCartListenerCallback]);

  const userLoginOrSignUpCallback = useCallback(
    async (signUp?: boolean) => {
      try {
        await getSession(true);
        const cartId = Cookies.get(Cookie.CART_GUEST_ID);
        const cartProvider = Cookies.get(Cookie.CART_GUEST_PROVIDER);
        const oldItemCount = cartCountAsNumber;
        let itemCount: number;
        if (cartId && cartProvider) {
          ({ totalLineQuantities: itemCount } = await postUserReplacement(cartId, cartProvider));
          itemCount = itemCount ?? 0;
          setCartCallback(itemCount);
          sendCartMergeConflictEvent(itemCount.toString());
          if (!onCartPage && oldItemCount != 0) {
            window.sessionStorage?.setItem(showMergeNotificationStorageVariable, 'true');
          }
        } else {
          itemCount = await setCartCookiesAndUpdateCounterDefault0();
        }
        Cookies.remove(Cookie.CART_GUEST_ID, { domain: getCookieDomain, path: getRetailUnitCookiePath });
        Cookies.remove(Cookie.CART_GUEST_PROVIDER, { domain: getCookieDomain, path: getRetailUnitCookiePath });
        Cookies.remove(Cookie.MV_CART_ID, { path: getRetailUnitCookiePath, domain: getCookieDomain });
        window.ikea.pubsub.publish(Actions.USER_LOGGED_IN_AGENT_POST_CART_WORK_SUCCESS, null);
        const toastWelcomeMessage = signUp ? 'fa.mvAgent.toast.accountCreated.label' : 'fa.mvAgent.toast.login.label';
        if (onCartPage && oldItemCount != 0 && itemCount != oldItemCount) {
          toast.set({
            text: t('fa.mvAgent.toast.loginMergedCarts.label', { count: itemCount }),
          });
          cartMergeListeners.forEach((listener) => listener('merged'));
        } else {
          cartMergeListeners.forEach((listener) => listener('did-nothing'));

          if (!onProfilePage && !onFavouritesPage) {
            toast.set({
              text: t(toastWelcomeMessage),
            });
          }
        }
      } catch {
        window.ikea.pubsub.publish(Actions.USER_LOGGED_IN_AGENT_POST_CART_WORK_FAIL, null);
        cartMergeListeners.forEach((listener) => listener('failed'));
      }
    },
    [
      cartCountAsNumber,
      getSession,
      sendCartMergeConflictEvent,
      setCartCookiesAndUpdateCounterDefault0,
      setCartCallback,
      showMergeNotificationStorageVariable,
      onCartPage,
      onFavouritesPage,
      onProfilePage,
      toast,
    ],
  );

  const userSignUpCallback = useCallback(() => userLoginOrSignUpCallback(true), [userLoginOrSignUpCallback]);
  const userLoginCallback = useCallback(() => userLoginOrSignUpCallback(false), [userLoginOrSignUpCallback]);

  const listenForUserLogin = useCallback(() => {
    window.ikea.pubsub.subscribe(Actions.USER_LOGGED_IN, userLoginCallback);
  }, [userLoginCallback]);

  const listenForUserSignUp = useCallback(() => {
    window.ikea.pubsub.subscribe(Actions.PROFILE_SIGN_UP, userSignUpCallback);
  }, [userSignUpCallback]);

  const userLogoutCallback = useCallback(async () => {
    Cookies.remove(Cookie.MV_CART_ID, { path: getRetailUnitCookiePath, domain: getCookieDomain });
    Cookies.set(Cookie.USER_LOGGEDIN, 'false', { domain: getCookieDomain, path: getRetailUnitCookiePath });
    window.sessionStorage?.removeItem(showMergeNotificationStorageVariable);
    setCartCallback(0);
    window.ikea.pubsub.publish(Actions.USER_LOGGED_OUT_AGENT_POST_CART_WORK_SUCCESS, null);
  }, [showMergeNotificationStorageVariable, setCartCallback]);

  const listenForUserLogout = useCallback(() => {
    window.ikea.pubsub.subscribe(Actions.USER_LOGGED_OUT, userLogoutCallback);
  }, [userLogoutCallback]);

  const execSubscribeListeners = useCallback(() => {
    if (!window?.ikea && !window.ikea?.pubsub) {
      return;
    }
    listenForAddToCart();
    listenForUserLogin();
    listenForUserSignUp();
    listenForUserLogout();
    window.ikea.mvAgent = window.ikea.mvAgent || {};
    const cartApi = {
      agentLoaded: true,
      setCartCount: setCartCallback,
      increaseCartCount,
      decreaseCartCount,
      currentCartCount: cartCountAsNumber,
    };
    window.ikea.shoppingCart = cartApi;
    window.ikea.mvAgent.cart = cartApi;
    if (!cartAgentLoaded) {
      setCartAgentLoaded(true);
      document.body.dispatchEvent(new Event(Actions.CART_AGENT_LOADED, { bubbles: true, cancelable: true }));
      window.ikea.pubsub.publish(Actions.CART_AGENT_LOADED, null);

      if (signUpRedirect) {
        userSignUpCallback();
      } else if (loginRedirect) {
        userLoginCallback();
      }
    }
  }, [
    cartAgentLoaded,
    cartCountAsNumber,
    decreaseCartCount,
    increaseCartCount,
    listenForAddToCart,
    listenForUserLogin,
    listenForUserLogout,
    listenForUserSignUp,
    loginRedirect,
    setCartCallback,
    signUpRedirect,
    userLoginCallback,
    userSignUpCallback,
  ]);

  const dispatchPendingNotifications = useCallback(() => {
    const shouldShowToast = window.sessionStorage?.getItem(showMergeNotificationStorageVariable);
    if (onCartPage && cartCountAsNumber && shouldShowToast) {
      window.sessionStorage?.removeItem(showMergeNotificationStorageVariable);
      toast.set({
        text: t('fa.mvAgent.toast.mergedCarts.text', { count: cartCountAsNumber }),
      });
    }
  }, [showMergeNotificationStorageVariable, cartCountAsNumber, onCartPage, toast]);

  const execUnsubscribeListeners = useCallback(() => {
    if (!window?.ikea && !window.ikea?.pubsub) {
      return;
    }
    window.ikea.pubsub.unsubscribe(Actions.ADD_TO_CART_ACTION, addToCartListenerCallback);
    window.ikea.pubsub.unsubscribe(Actions.USER_LOGGED_IN, userLoginCallback);
    window.ikea.pubsub.unsubscribe(Actions.PROFILE_SIGN_UP, userSignUpCallback);
    window.ikea.pubsub.unsubscribe(Actions.USER_LOGGED_OUT, userLogoutCallback);
  }, [addToCartListenerCallback, userLoginCallback, userLogoutCallback, userSignUpCallback]);

  const unsubscribePubsub = useCallback(() => {
    if (document.readyState === 'complete' || window.ikea.pubsub) {
      execUnsubscribeListeners();
    } else {
      window.addEventListener('load', execUnsubscribeListeners);
    }
  }, [execUnsubscribeListeners]);

  const mvCartAgent = useCallback(async () => {
    if (document.readyState === 'complete' || window.ikea.pubsub) {
      execSubscribeListeners();
      dispatchPendingNotifications();
    } else {
      window.addEventListener('load', execSubscribeListeners);
    }
  }, [execSubscribeListeners, dispatchPendingNotifications]);

  useEffect(() => {
    mvCartAgent();
    return unsubscribePubsub;
  }, [mvCartAgent, unsubscribePubsub]);

  return {
    showItemDoesNotExistModal,
    setShowItemDoesNotExistModal,
  };
};
