import './Modal.scss';

import SkapaModal, {
  ModalBody as SkapaModalBody,
  ModalBodyProps as SkapaModalBodyProps,
  ModalFooter as SkapaModalFooter,
  ModalFooterProps as SkapaModalFooterProps,
  ModalHeader as SkapaModalHeader,
  ModalHeaderProps as SkapaModalHeaderProps,
  ModalProps as SkapaModalProps,
  Prompt as SkapaPrompt,
  PromptProps as SkapaPromptProps,
  Sheets as SkapaSheets,
  SheetsProps as SkapaSheetsProps,
} from '@ingka/modal';

import { PREFIX } from '../constants/constants';

export interface CustomSkapaModalProps extends SkapaModalProps {
  closeButtonGtm?: string;
}

export enum SheetSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

const Modal = ({ ...props }: CustomSkapaModalProps) => <SkapaModal {...props} prefix={PREFIX} />;
export const ModalHeader = (props: SkapaModalHeaderProps) => <SkapaModalHeader {...props} prefix={PREFIX} />;
export const ModalFooter = (props: SkapaModalFooterProps) => <SkapaModalFooter {...props} prefix={PREFIX} />;
export const ModalBody = (props: SkapaModalBodyProps) => <SkapaModalBody {...props} prefix={PREFIX} />;
export const Sheets = (props: SkapaSheetsProps) => <SkapaSheets {...props} prefix={PREFIX} />;
export const Prompt = (props: SkapaPromptProps) => <SkapaPrompt {...props} prefix={PREFIX} />;

export default Modal;
