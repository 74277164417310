import './LoginModal.scss';

import Cookies from 'js-cookie';

import { useCallback, useEffect, useState } from 'react';

import { Actions } from '../../../actions/enums';
import { PREFIX } from '../../Skapa/constants/constants';
import { splitPath } from '@mvecom/common/utils/splitPath';
import { Wrapper } from '../standardModalWrapper';

import { LoginEvent, useLogin } from '../../../hooks/useLogin';

import { LoginPage } from '../../Login/LoginPage';
import { ProfilePagesUrls } from '../../../utils/Urls';
import { useCartMergeListener } from '../../../hooks/useCart';
import { useFetchMenu } from '../../../hooks/useMenu';
import { MenuLoggedIn } from './Menu';

export const LoginModal = () => {
  const [state, setState] = useState<'closed' | 'reminder' | 'reminder-merge-check' | 'menu'>('closed');

  const close = useCallback(() => setState('closed'), [setState]);

  useCartMergeListener(
    useCallback(
      (mergeStatus) => {
        if (state !== 'reminder' && state !== 'reminder-merge-check') {
          return;
        }

        switch (mergeStatus) {
          case 'merged':
          case 'failed':
            close();
            return;
          case 'did-nothing':
            window.ikea.pubsub.publish(Actions.GO_TO_CHECKOUT);
            return;
          default:
            mergeStatus satisfies never;
        }
      },
      [close, state],
    ),
  );

  const onLoginEvent = useCallback(
    (ev: LoginEvent) => {
      if (ev === 'initial-auth-check-false') {
        // nothing needs to be done
      } else if (ev === 'logged-out') {
        setState('closed');
      } else if (ev === 'login-success') {
        if (state === 'menu') {
          setState('closed');
        } else if (state === 'reminder') {
          setState('reminder-merge-check');
        }
      } else if (ev === 'validation-failure') {
        window.location.href = ProfilePagesUrls.missingInfo();
      }
    },
    [state],
  );

  const loginState = useLogin(state !== 'closed', onLoginEvent);
  const { loggedIn, login, loginError, loadingLoggedIn, loginLoading } = loginState;
  const menu = useFetchMenu(loggedIn);

  const setLoggedOut = loginState.setLoggedOut;

  useEffect(() => {
    if (menu.status === 'error' && menu.axiosStatusCode === 401) {
      setLoggedOut();
    }
  }, [menu, setLoggedOut]);

  const onLoggedOutEvent = useCallback(() => {
    menu.clear();
    setState('closed');
  }, [menu]);

  // listen to pubsub messages for opening the menu
  useEffect(() => {
    const open = () => {
      if (!onProfilePages()) {
        /*
          We can handle menu better on profile pages
          faster loading, avoid whole navigation when clicking on links
        */
        setState('menu');
      }
    };

    const openLoginReminder = () => {
      if (Cookies.get('_session_id')) {
        window.ikea.pubsub.publish(Actions.GO_TO_CHECKOUT);
      } else {
        setState('reminder');
      }
    };

    window?.ikea?.pubsub?.subscribe(Actions.PROFILE_OPEN_MENU, open);
    window?.ikea?.pubsub?.subscribe(Actions.PROFILE_OPEN_LOGIN_REMINDER, openLoginReminder);

    return () => {
      window?.ikea?.pubsub?.unsubscribe(Actions.PROFILE_OPEN_MENU, open);
      window?.ikea?.pubsub?.unsubscribe(Actions.PROFILE_OPEN_LOGIN_REMINDER, openLoginReminder);
    };
  }, [loggedIn, setState]);

  // listen to post messages
  useEffect(() => {
    const ev = (event: MessageEvent) => {
      const pubsubStart = 'pubsub::';

      if (event.data === 'menu-loaded' && !onProfilePages()) {
        setState('menu');
      } else if (event.data === 'menu-close') {
        close();
      } else if (typeof event.data === 'string' && event.data.startsWith(pubsubStart)) {
        window?.ikea?.pubsub?.publish?.(event.data.slice(pubsubStart.length));
      }
    };
    window.addEventListener('message', ev);
    return () => window.removeEventListener('message', ev);
  }, [close]);

  if (state === 'closed') {
    return <Wrapper visible={false} close={close} />;
  }

  if (state === 'menu') {
    if (loadingLoggedIn || (loggedIn && !menu.data)) {
      return <Wrapper loading visible close={close} />;
    }

    return (
      <Wrapper noPadding={!!menu.data} visible close={close}>
        {menu.data ? (
          <MenuLoggedIn menu={menu.data} onLoggedOut={onLoggedOutEvent} />
        ) : (
          <LoginPage
            closeMenu={close}
            isReminder={false}
            skapaPrefix={PREFIX}
            login={login}
            loading={loginLoading}
            loginError={loginError}
          />
        )}
      </Wrapper>
    );
  }

  if (state === 'reminder' || state === 'reminder-merge-check') {
    return (
      <Wrapper visible close={close}>
        <LoginPage
          closeMenu={close}
          isReminder
          skapaPrefix={PREFIX}
          login={login}
          loading={loginLoading || state === 'reminder-merge-check'}
          loginError={loginError}
        />
      </Wrapper>
    );
  }

  state satisfies never;
  console.error('Unknown state in menu', state);
  return <Wrapper visible={false} close={close} />;
};

function onProfilePages() {
  const [, , appName] = splitPath(window.location.pathname);
  return appName === 'profile';
}
