import { Endpoints } from '../../enums';
import { axiosRetryDefault, authAxiosInstance as network } from '../../axios';
import { AxiosError } from 'axios';
import { GetAuth0SessionResponse } from '../../types';

export const getAuth0Session = async (signal?: AbortSignal) => {
  const response = await network
    .get<GetAuth0SessionResponse>(Endpoints.GetAuth0Session, {
      signal,
      'axios-retry': {
        retryCondition: (error: AxiosError<any>) => {
          if (error.response?.status === 400) return true;
          return axiosRetryDefault(error);
        },
      },
    })
    .catch((e) => {
      console.error('error in getAuth0Session', e);
      throw e;
    });

  return response.data;
};
